<template>
  <b-container class="payment-method-form-container">
    <b-form-row>
      <b-col cols="12" md="12">
        <b-form-group
          id="business-account-group"
          label="Account Holder Type"
          class="text-default"
        >
          <b-form-radio-group
            v-model="ach.business_account"
            label="Account Holder Type"
          >
            <b-form-radio
              :value="true"
              class="account-holder-selection"
            >
              Company
            </b-form-radio>
            <b-form-radio
              :value="false"
              class="account-holder-selection"
            >
              Individual
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <div v-if="ach.business_account === true">
      <b-form-row>
        <b-col cols="12" md="6">
          <b-form-group
            id="company-name-group"
            label="Name on Account"
            class="text-default"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="minmax:1,30|required"
              name="Name on Account"
            >
              <b-form-input
                id="company-name-input"
                v-model="ach.company_name"
                type="text"
                class="text-input"
                :state="!errors[0] ? null: false"
              />
              <b-form-invalid-feedback :state="!errors[0] ? null: false">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-form-row>
    </div>
    <div v-else>
      <b-form-row>
        <b-col cols="12" md="6">
          <b-form-group
            id="account-holder-first-name-group"
            label="Account Holder First Name"
            class="text-default"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="minmax:1,20|required"
              name="Account Holder First Name"
            >
              <b-form-input
                id="first-name-input"
                v-model="ach.first_name"
                type="text"
                class="text-input"
                :state="!errors[0] ? null: false"
              />
              <b-form-invalid-feedback :state="!errors[0] ? null: false">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group
            id="account-holder-last-name-group"
            label="Account Holder Last Name"
            class="text-default"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="minmax:1,20|required"
              name="Account Holder Last Name"
            >
              <b-form-input
                id="last-name-input"
                v-model="ach.last_name"
                type="text"
                class="text-input"
                :state="!errors[0] ? null: false"
              />
              <b-form-invalid-feedback :state="!errors[0] ? null: false">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-form-row>
    </div>
    <b-form-row>
      <b-col cols="12" md="6">
        <b-form-group
          id="account-nickname-group"
          label="Account Nickname"
          class="text-default"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="minmax:1,20|required"
            name="Account Nickname"
          >
            <template>
              <b-form-input
                id="account-name-input"
                v-model="ach.nickname"
                type="text"
                class="text-input"
                :state="!errors[0] ? null : false"
              />
              <b-form-invalid-feedback :state="!errors[0] ? null: false">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </template>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Account Type" class="text-default">
          <b-select
            v-model="ach.bank_account_type"
            class="text-input"
            :options="accountTypeOptions"
            @change="updateSelectedAccountType"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <warning-banner
      v-if="editMode"
      message="Routing and account numbers are not editable. Delete payment method and add it again to make changes."
    />
    <b-form-row>
      <b-col cols="12" md="6">
        <b-form-group
          id="routing-number-group"
          label="Routing Number"
          class="text-default"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="length:9|required"
            name="Routing Number"
          >
            <b-form-input
              id="routing-number-input"
              v-model="ach.aba_routing_number"
              type="number"
              class="text-input"
              :disabled="editMode"
              :placeholder="editMode ? hiddenNumber : '#########'"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="!editMode" cols="12" md="6">
        <b-form-group
          id="confirm-routing-number-group"
          label="Confirm Routing Number"
          class="text-default"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="`confirm-routing-number:${ach.aba_routing_number}`"
            name="Confirm Routing Number"
          >
            <b-form-input
              id="confirm-routing-number-input"
              type="number"
              class="text-input"
              :placeholder="editMode ? hiddenNumber : '#########'"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group
          id="bank-account-number-group"
          label="Bank Account Number"
          class="text-default"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Bank Account Number"
          >
            <b-form-input
              id="bank-account-number-input"
              v-model="ach.bank_account_number"
              type="number"
              class="text-input"
              :disabled="editMode"
              :placeholder="editMode ? hiddenNumber : '###############'"
              :state="!errors[0] ? null: false"
              @change="updateBankAccountNumber"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="!editMode" cols="12" md="6">
        <b-form-group
          id="confirm-bank-account-number-group"
          label="Confirm Bank Account Number"
          class="text-default"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="`confirm-number:${ach.bank_account_number}`"
            name="Confirm Bank Account Number"
          >
            <b-form-input
              id="confirm-bank-account-number-input"
              v-model="confirmBankAccountNumber"
              type="number"
              class="text-input"
              :placeholder="editMode ? hiddenNumber : '###############'"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-form-row>
    <div class="agreement-section">
      <p class="sample-check-text">
        Example of a check
      </p>
      <b-img :src="`/images/automatedClearingHouses/whole-check-sample.png`" class="mr-2 sample-check" />
    </div>
  </b-container>
</template>
<script>

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import WarningBanner from '@/components/shared/WarningBanner.vue'

extend('required', {
    ...required,
    message: '{_field_} is required',
  })

  extend('confirm-number', {
    validate(value, _ref) {
      const bankAccountNumber = _ref[0]
      return value === bankAccountNumber
    },
    message: 'Number does not match Bank Account Number',
  })

  extend('confirm-routing-number', {
    validate(value, _ref) {
      const routingNumber = _ref[0]
      return value === routingNumber
    },
    message: 'Number does not match Routing Number',
  })

  export default {
    name: 'AutomatedClearingHouseForm',
    components: { WarningBanner },
    props: {
      editAch: null,
    },
    data() {
      return this.initialData()
    },
    computed: {
      accountTypeOptions() {
        return ['Checking', 'Savings']
      },
      editMode() {
        return !!this.editAch
      },
      hiddenNumber() {
        this.setMaskedBankAccountNumber()
        return this.masked_bank_account_number
      },
    },
    watch: {
      cardNumber() {
        if (this.cardNumber) {
          this.ach.number = this.cardNumber.replace(/\s/g, '')
        }
      },
      editAch() {
        if (this.editAch !== null) {
          this.ach = { ...this.ach, ...this.editAch }
        }
        else {
          Object.assign(this.$data, this.initialData())
        }
      },
      ach: {
        deep: true,
        handler() {
          this.$emit('changed', this.ach)
        },
      },
      'ach.business_account': function(newVal) {
        if (newVal === true) {
          this.ach.first_name = null
          this.ach.last_name = null
        } else if (newVal === false) {
          this.ach.company_name = null
        }
      },
    },
    mounted() {
      if(this.editAch !== null) {
        this.ach = { ...this.ach, ...this.editAch }
      }
    },
    methods: {
      initialData(){
        return {
          confirmBankAccountNumber: null,
          ach: {
            company_name: null,
            first_name: null,
            last_name: null,
            nickname: null,
            aba_routing_number: null,
            bank_account_number: null,
            masked_bank_account_number: null,
            bank_account_type: 'Checking',
            business_account: true,
            agreement_indicator: false,
          },
        }
      },
      setMaskedBankAccountNumber() {
        this.masked_bank_account_number = this.editAch.bank_account_number.replace(/X/g, '*')
      },
      updateBankAccountNumber(accountNumber) {
        this.ach.bank_account_number = accountNumber
      },
      updateSelectedAccountType(accountType) {
        this.ach.bank_account_type = accountType
      },
    },
  }
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=checkbox], input[type=radio] , select {
  cursor: pointer;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]:disabled {
  cursor: not-allowed;
}

.sample-check {
  width: 100%;
}

.sample-check-text,
.text-default {
  font-size: 1.0rem;
  font-weight: 400;
  color: #4E4E52;
}

@media only screen and (max-width: 576px) {
  .payment-method-form-container {
    margin-bottom: 1.0rem;
  }

  .sample-check-text,
  .text-default {
    padding-left: 0.5rem;
    font-size: 0.75rem;
  }

  .account-holder-selection {
    ::v-deep .custom-control-label {
      padding-top: 0.25rem;
    }
  }

  .text-input {
    font-size: 0.875rem;
  }
}
</style>
